@media screen and (min-width: 1100px) {
    h2 {
        font-size: calc(1em + 1vw);
    }
    
    header {
        position: relative;
        width: 80%;
        margin: 0 auto;
        left: 0px;
        right: 0px;
    }

    body {
        font-size: calc(.7em + .5vw);
    }

    #header-logo {
        margin: 0;
    }

    #header-nav {
        display: initial;
    }

    #header-nav a {
        margin-left: 2vw;
    }

    #header-nav .button {
        background-color: var(--purple-color);
        padding: 15px 40px;
    }

    #header-nav .button:hover {
        background-color: var(--red-color);
    }

    #home {
        display: flex;
        flex-wrap: unset;
        margin: 0;
        margin-top: calc(-10vh - 30px);
        height: 100vh;
    }

    #home .content {
        display: flex;
        flex-direction: column;
        font-size: calc(1em + .4vw);
        padding: unset;
    }

    #home .content h1 {
        display: initial;
        font-family: SourceCodePro;
    }    

    #home .content-figure {
        position: initial;
        margin: 0;
        padding-top: 30px;
    }

    #home .content-figure svg {
        flex-shrink: 0;
        width: 150%;
        transform: rotate(-32deg);
    }

    .block {
        padding: 0 10%;
    }
    
    section {
        display: flex;
        flex-wrap: wrap;
    }

    .section-break {
        flex-basis: 100%;
    }

    #services {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }

    .service .content-figure img {
        max-width: 90%;
    }

    .content-block {
        display: flex;
        align-content: space-between;
        flex-basis: 50%;
        padding: 10px 0px;
    }

    .content-figure {
        height: 100%;
        width: 100%;
        margin: 0;
    }

    .service {
        flex-basis: 48%;
        margin-top: 6%;
    }

    .service .content {
        margin: 0;
    }

    .service .content h2 {
        text-align: left;
    }

    .content-figure {
        flex: 1;
    }

    .content {
        margin: 0px 15px;
        flex: 1;
    }

    #process .section-break {
        margin-top: 10vh;
    }

    #process .content-block {
        flex-basis: 100%;
        align-items: center;
    }

/*
    .process-step .content-figure {
        display: flex;
        justify-content: center;
        height: 70%;
    }
  */  
    .process-step .content-step {
        width: 0;
        height: 0;
        display: none;
    }

    .process-step .desktop--content-step {
        display: initial;
        margin: 0 5vw;
    }

    .process-step .desktop--content-step h3 {
        font-size: 2em;
        color: #fff;
        background-color: var(--purple-color);
        padding: 20px;
        border-radius: 10px;
    }

    .process-step .spacer {
        flex: .75;
    }

    .process-step:nth-of-type(3) .desktop--content-step {
        order: 1;
        margin: 0 5vw 0 0;
    }

    .process-step:nth-of-type(3) .content-figure {
        order: 2;
        margin-right: 100px;
    }

    .process-step:nth-of-type(3) .content {
        order: 3;
    }

    .process-step:nth-of-type(5) .desktop--content-step {
        order: 1;
        margin: 0 5vw 0 0;
    }

    .process-step:nth-of-type(5) .content {
        order: 2;
        margin-right: 5vw;
    }

    .process-step:nth-of-type(5) .content-figure {
        order: 3;
    }

    .image-separator {
        width: 5vw;
        margin: 2vh 10vw;
    }

    .image-separator2 {
        width: 4vw;
        margin-left: -6%;
    }

    #about {
        justify-content: space-between;
        max-width: 75vw;
        border-radius: 0 20px 20px 0;
    }

    #about h2 {
        font-size: calc(1em + .42vw);
        margin: .5vh 0;
    }

    #about .content {
        margin: 0;
    }

    #about .larger {
        flex-basis: 30%;
    }

    .team-member {
        flex-direction: column;
        flex-basis: 20%;
        height: 100%;
    }

    .team-member .content {
        flex-basis: 50%;
    }

    .team-member .content-figure {
        margin-bottom: 1vh;
        flex-shrink: 0;
        flex-basis: 50%;
    }

    .team-member .content-figure img {
        width: 100%;
    }

    #contact {
        padding: 10vh 5vw;
        background-size: auto 110vw;
        background-position: -23vw 20%;
    }

    #contact .content-figure {
        flex: 2;
        display: initial;
    }
}
